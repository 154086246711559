import store from "@/store";

export function checkRouteName (url) {
  let urlInfo = {}
  let hasDirectContinue = url.indexOf("/direct-continue") !== -1;
  if (hasDirectContinue) {
    let urlPath = url.split('/')
    let id = urlPath[4]
    let tab = urlPath[5]
    urlInfo['urlPath'] = 'direct-continue'
    urlInfo['id'] = id
    urlInfo['tab'] = tab
  }
  return urlInfo
}

export function checkGeneleibermanDownline () {
  let isGeneDownline = store?.getters?.agentGroupLandingInfo?.is_gene_downline
  if (isGeneDownline) {
    return true
  } else {
    return false
  }
}